<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <input-qr-scan-model
          ref="inputQr_1"
          v-model="code"
          :label="label"
          @keyupEnter="inputScan"
        ></input-qr-scan-model>

        <!-- <div class="error--text font-italic fs-14">
          {{ $t("messages.goods_expired_date_location_note") }}
        </div> -->
      </v-col>

      <v-col cols="12" md="12">
        <v-alert color="primary" text>
          <div class="mb-2">
            {{ $t("labels.available_position_closest") }}
          </div>
          <v-text-field
            v-model="current_cell_id"
            class="c-input-xs mb-5"
            :label="$t('labels.scan_position_standing')"
            :placeholder="$t('labels.scan_position_standing')"
            @keyup.enter="suggestByCell"
            dense
            outlined
            clearable
            hide-details
          ></v-text-field>

          <div class="mb-5" v-if="suggest_cells.length > 0">
            {{ $t("labels.suggest") }}:
            <span
              style="font-size: 16px; font-weight: 600; color: red"
              v-for="c in suggest_cells"
              :key="`${c.cell_id}_${c.indexing}`"
            >
              {{ c.cell_id }};
            </span>
          </div>
        </v-alert>
      </v-col>
    </v-row>

    <v-dialog v-model="itemDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h6">
          {{ $t("labels.customer") }}
        </v-card-title>

        <v-card-text>
          <v-simple-table fixed-header class="table-padding-2">
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.customer") }}
                  </th>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.sku") }}
                  </th>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.barcode") }}
                  </th>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.name") }}
                  </th>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.goods_description") }}
                  </th>
                  <!-- <th role="columnheader" class="text-center">
                    {{ $t("labels.position") }}
                  </th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="goods in goods_customers"
                  :key="`i_${goods.id_goods}`"
                  class="text-center"
                >
                  <td>
                    {{ goods.company_name }}
                    <br />
                    <v-btn
                      color="info"
                      outlined
                      x-small
                      @click="selectItem(goods)"
                    >
                      {{ $t("labels.select") }}
                    </v-btn>
                  </td>
                  <td>{{ goods.sku }}</td>
                  <td>{{ goods.customer_goods_barcode }}</td>
                  <td>{{ goods.goods_name }}</td>
                  <td>{{ goods.description }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import moment from "moment";

export default {
  name: "LocationChange",
  components: {},
  data: () => ({
    current_cell_id: null,
    code: null,
    item: {},
    suggest_cells: [],
    goods_customers: [],
    itemDialog: false,
    isLoading: false,
  }),
  computed: {
    label() {
      if (!this.item || !this.item.id_goods) {
        return this.$t("labels.uid_sku_barcode");
      }

      if (!this.item.old_cell_id) {
        return this.$t("labels.old_position");
      }

      return this.$t("labels.new_position");
    },
  },
  created() {},
  methods: {
    nextInputFocus(index) {
      if (index > 10) {
        return false;
      }
      this.$nextTick(() => {
        if (this.$refs[`inputIndex_${index + 1}`]) {
          this.$refs[`inputIndex_${index + 1}`].focus();
        } else {
          this.nextInputFocus(index + 1);
        }
      });
    },

    onResetAll() {
      this.current_cell_id = null;
      this.suggest_cells = [];
      this.code = null;
      this.item = {};
    },

    onWarehouseChange() {
      this.onResetAll();
      setTimeout(() => {
        this.$refs.inputQr_1.focusInput();
      }, 100);
    },

    onCustomerChange() {
      setTimeout(() => {
        this.$refs.inputQr_2.focusInput();
      }, 100);
    },

    async suggestByCell() {
      try {
        const { data } = await httpClient.post(
          "/warehouse-location/v1/suggest-by-cell",
          {
            cell_id: this.current_cell_id,
          }
        );
        this.suggest_cells = [...data];
        this.current_cell_id = null;
        document.getElementById("success_sound_player").play();
        this.nextInputFocus(2);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(errMsg);
      }
    },

    selectItem(item) {
      this.item = { ...item };

      const storage_location = (this.item && this.item.storage_location) || "";
      if (!storage_location) {
        this.$vToastify.error(this.$t("messages.goods_no_old_position"));
        document.getElementById("error_sound_player").play();
        return this.onResetAll;
      }

      document.getElementById("success_sound_player").play();
      this.hideDialogSelectItem();
    },

    showDialogSelectItem() {
      this.itemDialog = true;
    },

    hideDialogSelectItem() {
      this.itemDialog = false;
    },

    async inputScan() {
      if (!this.code) {
        return false;
      }

      if (!this.item || !this.item.id_goods) {
        return this.getGoods();
      }

      if (!this.item.old_cell_id) {
        return this.checkOldLocation();
      }

      return this.setLocation();
    },

    async checkOldLocation() {
      const storage_location = (this.item && this.item.storage_location) || "";
      if (!storage_location) {
        this.code = null;
        this.$vToastify.error(this.$t("messages.goods_no_old_position"));
        document.getElementById("error_sound_player").play();
        return false;
      }

      const locations = storage_location.split("; ");
      if (!locations.includes(this.code)) {
        this.code = null;
        this.$vToastify.error(this.$t("messages.wrong_old_position"));
        document.getElementById("error_sound_player").play();
        return false;
      }

      this.item = { ...this.item, old_cell_id: this.code };
      this.code = null;
      document.getElementById("success_sound_player").play();
    },

    async getGoods() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        document.getElementById("error_sound_player").play();
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods/v1/get-by-code-or-uid-wp-multiple",
          {
            code: this.code,
          }
        );
        this.goods_customers = [...data];
        this.isLoading = false;
        this.code = null;

        if (data.length === 0) {
          this.$vToastify.error(this.$t("messages.goods_not_found"));
          document.getElementById("error_sound_player").play();
          return false;
        }

        const checkProcessExp = [...data].find(
          (item) => !!item.process_exp_date && !item.id_goods_special_barcode
        );

        if (checkProcessExp) {
          this.$vToastify.error(this.$t("messages.goods_expired_date"));
          document.getElementById("error_sound_player").play();
          return false;
        }

        if (data.length === 1) {
          const item = data[0];
          this.item = { ...item };

          const storage_location =
            (this.item && this.item.storage_location) || "";
          if (!storage_location) {
            this.$vToastify.error(this.$t("messages.goods_no_old_position"));
            document.getElementById("error_sound_player").play();
            return this.onResetAll;
          }

          document.getElementById("success_sound_player").play();
        } else {
          this.showDialogSelectItem();
        }
      } catch (e) {
        this.code = null;
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(errMsg);
      }
    },

    async setLocation() {
      if (this.code == this.item.old_cell_id) {
        this.code = null;
        this.$vToastify.warning(this.$t("messages.cant_same_position"));
        document.getElementById("error_sound_player").play();
        return false;
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        document.getElementById("error_sound_player").play();
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/warehouse-location/v1/change-location", {
          ...this.item,
          expired_date: this.item.expired_date
            ? moment(this.item.expired_date).format("YYYY-MM-DD")
            : null,
          new_cell_id: this.code,
          old_cell_id: this.item.old_cell_id,
        });
        this.isLoading = false;
        this.onResetAll();
        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.isLoading = false;
        this.code = null;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },
  },
};
</script>

<style scoped></style>
